import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { Title } from "components/Title";
import { DocumentListItem } from "../documents/components/DocumentListItem";
import { DocumentStatus } from "../documents/statusUtils";
import { CTAButton } from "./CTAButton/CTAButton";
import styles from "./MainComponent.module.scss";

function getStatusForVehicleApplicationDocument(status) {
  if (["in_progress", "new"].includes(status)) {
    return DocumentStatus.IN_REVIEW;
  }
  if (status === "rejected") {
    return DocumentStatus.REJECTED;
  }
  return null;
}

export const MainComponent = ({ data: vehicleApplicationData }) => {
  const { t } = useTranslation();

  const newVehicles =
    vehicleApplicationData?.data
      .filter(({ submitted_at }) => submitted_at !== null)
      .filter(({ status }) => status !== "approved") || [];

  return (
    <>
      <div className={styles.vehicleDocumentsListWrapper}>
        <Title level={2}>{t("pageDocument.addNewVehicles")}</Title>
        {newVehicles.map((vehicle) => (
          <div className={styles.vehicleDocumentsItemWrapper} key={vehicle.id}>
            <DocumentListItem
              newStatusIcon={true}
              type={vehicle.id}
              status={getStatusForVehicleApplicationDocument(vehicle.status)}
              title={vehicle.vehicle_registration}
              viewLink={
                vehicle.status === "rejected" &&
                routes.reviewNewVehicle.getURL(vehicle.id)
              }
              editLink={
                vehicle.status !== "rejected" &&
                routes.reviewNewVehicleEdit.getURL(vehicle.id)
              }
            />
          </div>
        ))}
      </div>
      <div className={styles.ctaBtnWrapper}>
        <CTAButton />
      </div>
    </>
  );
};
