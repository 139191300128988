import { useState, useEffect } from "react";
import { api } from "api";

import { FleetTermsAndConditionsModal } from "./FleetTermsAndConditionsModal";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useFlags } from "launchdarkly-react-client-sdk";

export const AcceptedFleetTermsAndConditionsRequired = ({ children }) => {
  const { selectedFleetId } = useSelectedFleet();
  const { releaseFleetSpecificPolicies } = useFlags();
  const [open, setOpen] = useState(false);

  const [acceptTermsAndConditionsForFleet] =
    api.endpoints.acceptTermsAndConditionsForFleet.useMutation();

  const { data, isLoading } =
    api.endpoints.checkAcceptanceOfTermsAndConditionsForFleet.useQuery({
      fleetId: selectedFleetId,
    });

  const { data: fleetPublicData, isLoading: isTermsContendLoading } =
    api.endpoints.getFleetPublicInfoById.useQuery({
      fleetId: selectedFleetId,
    });

  useEffect(() => {
    if (Boolean(releaseFleetSpecificPolicies)) {
      if (
        isLoading ||
        isTermsContendLoading ||
        typeof data?.accepted === "undefined" ||
        fleetPublicData?.data?.terms_and_conditions === null
      ) {
        return;
      }

      setOpen(!data?.accepted);
    }
  }, [
    isLoading,
    releaseFleetSpecificPolicies,
    isTermsContendLoading,
    data,
    fleetPublicData,
    open,
    setOpen,
  ]);

  return (
    <>
      <FleetTermsAndConditionsModal
        open={open}
        onSubmit={acceptTermsAndConditionsForFleet}
      />
      {children}
    </>
  );
};
