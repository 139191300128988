import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { routes } from "routes";
import { api } from "api";

import { Field } from "components/Field";
import { Button } from "components/Button";

import styles from "./StripeStatusSection.module.scss";

export function StripeStatusSection({ profile }) {
  const { t } = useTranslation();

  const isStripeActive =
    profile?.stripe_details_submitted && profile?.stripe_payouts_enabled;

  const [getStripeKycUrl, { isLoading: isGetStripeKycLoading }] =
    api.endpoints.getStripeKycUrl.useMutation();

  const handleStripeRedirect = () => {
    const url = window.location.origin + routes.stripeCompleted.getURL();

    getStripeKycUrl({
      id: profile?.id,
      refresh_url: url,
      return_url: url,
      type: isStripeActive ? "account_update" : "account_onboarding",
    })
      .unwrap()
      .then((response) => {
        window.location.href = response.url;
      });
  };

  return (
    <Field
      label={
        <div className={clsx(styles.flexContainer)}>
          <div>{t("field.stripeStatus")}</div>
          <p
            className={clsx(
              styles.badge,
              isStripeActive ? styles.infoBadge : styles.warningBadge,
            )}
          >
            {isStripeActive
              ? t("success.enabled")
              : t("error.informationNeeded")}
          </p>
        </div>
      }
      description={
        isStripeActive ? (
          <>
            <p>{t("pageProfile.updatingMayAddDelays")}</p>
            <br />
            <p>{t("pageProfile.tappingWillOpenStripeKYC")}</p>
          </>
        ) : (
          <p>{t("pageProfile.startReceivingPayouts")}</p>
        )
      }
    >
      <Button
        block
        uppercase
        variant="primary"
        shape="round"
        loading={isGetStripeKycLoading}
        disabled={isGetStripeKycLoading}
        onClick={handleStripeRedirect}
      >
        {isStripeActive
          ? t("action.updatePayoutInformation")
          : t("action.completePayoutSetup")}
      </Button>
    </Field>
  );
}
