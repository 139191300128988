import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Modal } from "components/Modal";

import { api } from "api";
import { routes } from "routes";

import { LoadingComp } from "components/LoadingComp";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Button } from "components/Button";

import { useQueryCustomDocumentTypes } from "../../documents";

import money from "./assets/money-white.svg";
import directions from "./assets/directions-white.svg";
import schedule from "./assets/schedule-white.svg";
import driver from "./assets/driver.jpg";

import styles from "./JoinFleetPage.module.scss";

export function JoinFleetPage() {
  const [isDocumentModalVisible, setDocumentModalVisible] = useState(false);
  const { fleetSlug } = useParams();
  const ldClient = useLDClient();
  const { t } = useTranslation();

  const { data } = api.endpoints.getMarketingPageByFleetSlug.useQuery({
    fleetSlug,
  });
  const response = data?.data?.content;
  const fleetId = data?.data?.fleet_id;

  useEffect(() => {
    const context = ldClient.getContext();
    const nextContextKey = String(fleetId);

    if (fleetId && context?.key !== nextContextKey) {
      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });
    }
  }, [ldClient, fleetId]);

  const {
    predefinedDriverDocumentTypes,
    customApplicationDriverDocumentTypes,
    customApplicationOnlyDocumentTypes,
    predefinedVehicleDocumentTypes,
    customApplicationVehicleDocumentTypes,
    isLoading: areDocumentTypesLoading,
  } = useQueryCustomDocumentTypes({ currentCompanyId: fleetId });

  const content = convertResponseToUIData(response);

  if (content === undefined) {
    return <LoadingComp loading fullScreen />;
  }

  const documentsToDisplay = [
    ...predefinedDriverDocumentTypes,
    ...customApplicationDriverDocumentTypes,
    ...customApplicationOnlyDocumentTypes,
    ...predefinedVehicleDocumentTypes,
    ...customApplicationVehicleDocumentTypes,
  ];

  return (
    <div
      className={styles.page}
      style={{
        backgroundImage: `url("${content.backgroundImageURL}")`,
      }}
    >
      <div
        className={styles.backdrop}
        style={{
          opacity: content.backdropOpacity,
        }}
      />
      <div className={styles.content}>
        <header>
          <h1 className={styles.title}>{content.title}</h1>
          <div className={styles.lead}>{content.lead}</div>
        </header>

        <section className={styles.columnList}>
          {content.columns.map((column) => (
            <div className={styles.column} key={column.title}>
              <div className={styles.columnIconWrapper}>
                <img src={column.iconURL} alt="" />
              </div>
              <div className={styles.columnTextWrapper}>
                <div className={styles.columnTitle}>{column.title}</div>
                <div className={styles.columnLead}>{column.lead}</div>
              </div>
            </div>
          ))}
        </section>

        <footer>
          <div className={styles.footer}>
            <div className={styles.primaryLinkWrapper}>
              <Link
                to={routes.onboardingSignUpPage.getURL(fleetSlug)}
                className={styles.primaryLink}
              >
                {content.primaryLink.label}
              </Link>
            </div>
            <div className={styles.secondaryText}>
              {content.secondaryLink.pretext}{" "}
              <Link
                to={routes.signIn.getURL(fleetSlug)}
                className={styles.secondaryLink}
              >
                {content.secondaryLink.label}
              </Link>
            </div>
          </div>

          <div className={styles.footerDocuments}>
            <button
              type="button"
              className={styles.secondaryLink}
              onClick={() => setDocumentModalVisible(true)}
            >
              {t("pageJoinFleet.whatDocumentsINeed")}
            </button>
          </div>

          <Modal
            centered
            visible={isDocumentModalVisible}
            footer={
              <Button
                shape="round"
                variant="primary"
                onClick={() => setDocumentModalVisible(false)}
              >
                {t("action.close")}
              </Button>
            }
            onCancel={() => setDocumentModalVisible(false)}
          >
            <Title>{t("pageJoinFleet.whatDocumentsINeed")}</Title>
            <Description>
              <p>{t("pageJoinFleet.youNeedTheseDocuments")}</p>
              <ul>
                {documentsToDisplay.map((documentType) => {
                  return <li key={documentType.name}>{documentType.name}</li>;
                })}
                {areDocumentTypesLoading ? (
                  <li>{t("processing.loading")}</li>
                ) : null}
              </ul>
            </Description>
          </Modal>
        </footer>
      </div>
    </div>
  );
}

function convertResponseToUIData(response) {
  if (response === undefined) {
    return undefined;
  }

  return {
    backgroundImageURL: driver,
    backdropOpacity: 0.5,

    title: response.header.headline,
    lead: response.header.sub_headline,
    columns: [
      {
        title: response.body.col1.headline,
        lead: response.body.col1.sub_headline,
        iconURL: schedule,
      },
      {
        title: response.body.col2.headline,
        lead: response.body.col2.sub_headline,
        iconURL: money,
      },
      {
        title: response.body.col3.headline,
        lead: response.body.col3.sub_headline,
        iconURL: directions,
      },
    ],

    primaryLink: {
      label: response.footer.sign_up_button,
    },

    secondaryLink: {
      label: response.footer.link,
      pretext: response.footer.text,
    },
  };
}
