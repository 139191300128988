import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

import { api } from "api";
import { routes } from "routes";

import { WaveLayout } from "components/WaveLayout";
import { Title } from "components/Title";
import { Container } from "components/Container";
import { Description } from "components/Description";

import { CompleteRegistrationForm } from "./CompleteRegistrationForm";
import { prepareErrorMessage } from "helpers/prepareErrorMessage";
import { errorSnack, loadingSnack, successSnack } from "helpers/message";

export function CompleteRegistrationPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email") ?? "";
  const url = searchParams.get("url") ?? "";

  const [logIn] = api.endpoints.login.useMutation();
  const [acceptUser] = api.endpoints.acceptUser.useMutation();

  const [acceptLatestUserAgreementVersion] =
    api.endpoints.acceptLatestUserAgreementVersion.useMutation();

  const navigate = useNavigate();

  async function doAcceptUser({params, body}) {
    try {
      await acceptUser({params, body}).unwrap();

      return true;
    } catch (err) {
      const content =
          prepareErrorMessage(err) ?? t("error.somethingWentWrong");

      errorSnack({
        content,
        key: "complete",
      });

      return false;
    }
  }

  async function doLogIn({ password, email }) {
    try {
      return await logIn({
        email,
        password,
      }).unwrap();
    } catch (error) {
      const content =
          prepareErrorMessage(error) ?? t("error.somethingWentWrong");


      errorSnack({
        content,
        key: "complete",
      });

      return null;
    }
  }

  async function onSubmit({ password, email }) {
    loadingSnack({
      content: t("processing.signingIn"),
      key: "complete",
    });

    const decodedUrl = atob(url);
    const queryString = decodedUrl.split("?")[1];
    const params = new URLSearchParams(queryString);
    const signature = Object.fromEntries(params.entries());

    const acceptUserSuccess = await doAcceptUser({
      params: signature,
      body: {
        password: password,
        email: email,
      }
    });

    if (!acceptUserSuccess) {
      return;
    }

    const loginRes = await doLogIn({ password, email });

    if (!loginRes) {
      return;
    }

    acceptLatestUserAgreementVersion();

    storeUserAuthToken(loginRes.meta.token);

    successSnack({
      content: t("success.signedIn"),
      key: "complete",
    });
    navigate(routes.index.getURL(), { replace: true });
  }

  const initialValues = useMemo(() => {
    return {
      email,
      password: "",
      passwordConfirm: "",
      acceptAgreement: false,
    };
  }, [email]);

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pageCompleteRegistration.welcome")}</Title>
        <Description>
          <p>{t("pageCompleteRegistration.completeDetails")}</p>
        </Description>
        <CompleteRegistrationForm
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Container>
    </WaveLayout>
  );
}


const storeUserAuthToken = (token) => {
  localStorage.setItem("auth_token", JSON.stringify(token));
};
