import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Field } from "components/Field";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Small } from "components/Small";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { SelectCountry, SelectCurrency } from "components/Select";

export function SelectAnAccountForPayoutsForm({ onSubmit }) {
  const { t } = useTranslation();
  const [country, setCountry] = useState("GB");
  const [currency, setCurrency] = useState("GBP");

  const [errors, setErrors] = useState({});

  function onSubmitInternal(event) {
    event.preventDefault();

    const form = {
      currency,
      country,
      accountNumber: event.target.elements.accountNumber.value,
      routingNumber: event.target.elements.routingNumber.value,
    };

    const errors = getFormErrors(form, t);

    if (hasErrors(errors)) {
      setErrors(errors);
      return;
    }

    if (typeof onSubmit === "function") {
      onSubmit(form);
    }
  }

  function onBlur(event) {
    const { name, value } = event.target;
    const errors = getFormErrors({ [name]: value }, t);

    if (hasErrors(errors)) {
      addErrorForField(name, errors[name]);
    }
  }

  function onChange(event) {
    const { name } = event.target;
    if (errors[name] !== undefined) {
      clearErrorForField(name);
    }
  }

  function clearErrorForField(name) {
    setErrors((errors) => ({
      ...errors,
      [name]: undefined,
    }));
  }

  function addErrorForField(name, value) {
    setErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  }

  console.log("country", country);
  return (
    <>
      <form onChange={onChange} onBlur={onBlur} onSubmit={onSubmitInternal}>
        <Title>
          {t("componentSelectPayoutAccountForm.selectPayoutAccount")}
        </Title>
        <Description>
          <p>{t("componentSelectPayoutAccountForm.description")}</p>
        </Description>

        <Field
          label={t("componentSelectPayoutAccountForm.currency")}
          htmlFor="currency"
        >
          <SelectCurrency
            onChange={(value) => {
              setCurrency(value);
            }}
            value={currency}
            id="currency"
            limit="availableOnBackend"
          />
        </Field>

        <Field
          label={t("componentSelectPayoutAccountForm.countryOfBankAccount")}
          htmlFor="country"
        >
          <SelectCountry
            id="country"
            onChange={(value) => setCountry(value)}
            value={country}
          />
        </Field>

        <Field
          label={t("componentSelectPayoutAccountForm.accountNumber")}
          htmlFor="accountNumber"
          error={errors.accountNumber}
        >
          <Input
            invalid={errors.accountNumber?.length > 0}
            type="text"
            id="accountNumber"
            name="accountNumber"
            placeholder={t(
              "componentSelectPayoutAccountForm.accountNumberPlaceholder",
            )}
          />
        </Field>

        <Field
          label={t("componentSelectPayoutAccountForm.routingNumber")}
          htmlFor="routingNumber"
          description={t(
            "componentSelectPayoutAccountForm.routingNumberDescription",
          )}
        >
          <Input
            type="text"
            id="routingNumber"
            name="routingNumber"
            placeholder={t(
              "componentSelectPayoutAccountForm.routingNumberPlaceholder",
            )}
          />
        </Field>

        <div style={{ paddingBottom: 16 }}>
          <Small>{t("componentSelectPayoutAccountForm.legal")}</Small>
        </div>

        <div>
          <Button block variant="primary" shape="round" type="submit">
            {t("action.save")}
          </Button>
        </div>
      </form>
    </>
  );
}

function getRules(t) {
  return [
    {
      field: "accountNumber",
      isValid: (value) => typeof value === "string" && value.length > 0,
      error: t("componentSelectPayoutAccountForm.accountNumberIsRequired"),
    },
  ];
}

function getFormErrors(form, t) {
  const errors = {};

  for (let field in form) {
    const firstFailedFieldRule = getRules(t)
      .filter((rule) => rule.field === field)
      .find((rule) => !rule.isValid(form[field]));

    errors[field] = firstFailedFieldRule?.error;
  }

  return errors;
}

function hasErrors(errors) {
  return Object.values(errors).filter(Boolean).length > 0;
}
