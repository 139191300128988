import { routes } from "routes";
import { useFlags } from "launchdarkly-react-client-sdk";
import { api } from "api";
import { useTranslation } from "react-i18next";
import keyBy from "lodash/keyBy";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { Title } from "components/Title";
import { Description } from "components/Description";
import { SingleScreen } from "components/SingleScreen";
import { LoadingComp } from "components/LoadingComp";

import { DocumentListItem } from "../components/DocumentListItem";

import { useQueryDriverCustomDocumentTypes } from "../useQueryDocumentTypes";
import { getExpiryGetterByDocumentTypeId } from "../getExpiryGetterByDocumentTypeId";
import { DocumentType } from "../DocumentType";
import { ValidationWrapper } from "../ValidationWrapper";
import { getLatestDocumentByStatusByType } from "../getLatestDocumentByStatusByType";
import { DocumentStatus, getDocumentStatusByDate } from "../statusUtils";

import styles from "./DocumentsPage.module.scss";
import { NewVehicleList } from "../../AddNewVehicle";

function getVehicleCustomDocumentTypeDeadlines(vehicles) {
  const customDocuments = [];

  vehicles.forEach((vehicle) => {
    (vehicle?.custom_document_type_deadlines || []).forEach((deadline) => {
      customDocuments.push(deadline);
    });
  });

  return customDocuments;
}

export const DocumentsPage = () => {
  const { t } = useTranslation();
  const { releaseAddNewVehicle } = useFlags();
  const { isNewDocumentStatusIconsEnabled } = useFeatureFlagsFromSearchParams();

  const newStatusIcon = !isNewDocumentStatusIconsEnabled;

  const { selectedDriverProfile, selectedFleetId } = useSelectedFleet();

  const {
    driver_vehicles: driverVehiclesOriginal = [],
    owner_vehicles: ownerVehiclesOriginal = [],
    custom_document_type_deadlines: driverCustomDocumentTypeDeadlines = [],
  } = selectedDriverProfile;

  /**
   * JUSTIFICATION FOR VEHICLES FILTERING
   *
   * `selectedDriverProfile` may have vehicles that are not a part of current
   * fleet (`currentCompanyId`), thus some operations with them (like creating
   * a document) are not possible.
   *
   * Hence, Viktor Osipov and Ihor Kunytskiy agreed on filtering vehicles on
   * the frontend as a faster way to achieve wished result.
   */
  const driverVehiclesFiltered = driverVehiclesOriginal.filter(
    (vehicle) => String(vehicle.fleet_id) === String(selectedFleetId),
  );
  const ownerVehiclesFiltered = ownerVehiclesOriginal.filter(
    (vehicle) => String(vehicle.fleet_id) === String(selectedFleetId),
  );

  const vehicleCustomDocumentTypeDeadlines =
    getVehicleCustomDocumentTypeDeadlines([
      ...driverVehiclesFiltered,
      ...ownerVehiclesFiltered,
    ]);

  const customDocumentTypeDeadlines = [
    ...vehicleCustomDocumentTypeDeadlines,
    ...driverCustomDocumentTypeDeadlines,
  ];

  const customDocumentTypeDeadlinesByCustomDocumentTypeId = keyBy(
    customDocumentTypeDeadlines,
    "custom_document_type_id",
  );

  const driverVehiclesWithoutOwnerVehicles = (() => {
    const ownerVehiclesById = ownerVehiclesFiltered.reduce((acc, vehicle) => {
      if (!acc.has(vehicle.id)) {
        acc.set(vehicle.id, vehicle);
      }

      return acc;
    }, new Map());

    return driverVehiclesFiltered.filter(
      (vehicle) => !ownerVehiclesById.has(vehicle.id),
    );
  })();

  const isVehicleDocumentsVisible =
    ownerVehiclesFiltered?.length > 0 || driverVehiclesFiltered?.length > 0;

  const selectedDriverProfileId = selectedDriverProfile?.id;

  const {
    predefinedDriverDocumentTypes,
    customDriverDocumentTypes,
    predefinedVehicleDocumentTypes,
    customVehicleDocumentTypes,
    isLoading: areDocumentTypesLoading,
  } = useQueryDriverCustomDocumentTypes(selectedDriverProfileId);

  const {
    data: driverDocumentsResponse,
    isLoading: areDriverDocumentsLoading,
  } = api.endpoints.getDriverDocuments.useQuery(selectedDriverProfileId, {
    skip: !selectedDriverProfileId,
  });

  const {
    data: vehicleApplicationData = { data: [] },
    isLoading: isVehicleApplicationLoading,
  } = api.endpoints.getVehicleApplications.useQuery({
    driverId: selectedDriverProfile.id,
  });

  const documents = driverDocumentsResponse?.data;
  const latestDocumentByStatusByType =
    getLatestDocumentByStatusByType(documents);

  if (
    areDocumentTypesLoading ||
    areDriverDocumentsLoading ||
    isVehicleApplicationLoading
  ) {
    return <LoadingComp loading fullScreen />;
  }

  return (
    <SingleScreen fullHeight>
      <ValidationWrapper>
        <Title level={2}>{t("pageDocument.driverDocuments")}</Title>
        <Description>
          {t("pageDocument.driverDocumentsDescription")}
        </Description>
        <div className={styles.statusWrapper}>
          {predefinedDriverDocumentTypes.map((type) => (
            <DocumentListItem
              newStatusIcon={newStatusIcon}
              key={type.id}
              type={type.id}
              status={getStatusForPredefinedDocument({
                documentType: type,
                latestDocumentByStatusByType,
                vehicleOrDriverProfile: selectedDriverProfile,
              })}
              title={type.name}
              content={type.short_description ?? type.description}
              viewLink={routes.driverDocument.getURL(type.id)}
              editLink={routes.driverDocumentEdit.getURL(type.id)}
            />
          ))}

          {customDriverDocumentTypes.map((type) => (
            <DocumentListItem
              newStatusIcon={newStatusIcon}
              key={type.id}
              type={type.id}
              updateBefore={
                customDocumentTypeDeadlinesByCustomDocumentTypeId[type.id]?.date
              }
              status={getStatusForCustomDocument({
                documentType: type,
                customDocumentTypeDeadlineByCustomDocumentTypeId:
                  customDocumentTypeDeadlinesByCustomDocumentTypeId,
                latestDocumentByStatusByType,
              })}
              title={type.name}
              content={type.short_description ?? type.description}
              viewLink={routes.driverDocument.getURL(type.id)}
              editLink={routes.driverDocumentEdit.getURL(type.id)}
            />
          ))}
        </div>

        {isVehicleDocumentsVisible ? (
          <div className={styles.vehicleDocumentsListWrapper}>
            <Title level={2}>{t("pageDocument.vehicleDocuments")}</Title>
            {[
              ...ownerVehiclesFiltered,
              ...driverVehiclesWithoutOwnerVehicles,
            ].map((vehicle) => {
              const vehicleLatestDocumentByStatusByType =
                getLatestDocumentByStatusByType(vehicle.documents);

              return (
                <div
                  className={styles.vehicleDocumentsItemWrapper}
                  key={vehicle.id}
                >
                  <Description>
                    {vehicle.make} {vehicle.model} - {vehicle.plate}
                  </Description>

                  {predefinedVehicleDocumentTypes.map((type) => (
                    <DocumentListItem
                      newStatusIcon={newStatusIcon}
                      key={type.id}
                      type={type.id}
                      status={getStatusForPredefinedDocument({
                        documentType: type,
                        latestDocumentByStatusByType:
                          vehicleLatestDocumentByStatusByType,
                        vehicleOrDriverProfile: vehicle,
                      })}
                      title={type.name}
                      content={type.short_description ?? type.description}
                      viewLink={routes.vehicleDocument.getURL(
                        type.id,
                        vehicle.id,
                      )}
                      editLink={
                        type.id === DocumentType.VEHICLE_DETAILS
                          ? undefined
                          : routes.vehicleDocumentEdit.getURL(
                              type.id,
                              vehicle.id,
                            )
                      }
                    />
                  ))}

                  {customVehicleDocumentTypes.map((type) => (
                    <DocumentListItem
                      newStatusIcon={newStatusIcon}
                      key={type.id}
                      type={type.id}
                      updateBefore={
                        customDocumentTypeDeadlinesByCustomDocumentTypeId[
                          type.id
                        ]?.date
                      }
                      status={getStatusForCustomDocument({
                        documentType: type,
                        latestDocumentByStatusByType:
                          vehicleLatestDocumentByStatusByType,
                        customDocumentTypeDeadlineByCustomDocumentTypeId:
                          customDocumentTypeDeadlinesByCustomDocumentTypeId,
                      })}
                      title={type.name}
                      content={type.short_description ?? type.description}
                      viewLink={routes.vehicleDocument.getURL(
                        type.id,
                        vehicle.id,
                      )}
                      editLink={routes.vehicleDocumentEdit.getURL(
                        type.id,
                        vehicle.id,
                      )}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        ) : null}
        {releaseAddNewVehicle && (
          <NewVehicleList data={vehicleApplicationData} />
        )}
      </ValidationWrapper>
    </SingleScreen>
  );
};

function getStatusForPredefinedDocument({
  documentType,
  latestDocumentByStatusByType,
  vehicleOrDriverProfile,
}) {
  if (
    latestDocumentByStatusByType?.[documentType.id]?.last?.status === "pending"
  ) {
    return DocumentStatus.IN_REVIEW;
  }

  if (
    latestDocumentByStatusByType?.[documentType.id]?.last?.status ===
    "disapproved"
  ) {
    return DocumentStatus.REJECTED;
  }

  return getDocumentStatusByDate(
    getExpiryGetterByDocumentTypeId(documentType.id)(vehicleOrDriverProfile),
  );
}

function getStatusForCustomDocument({
  documentType,
  latestDocumentByStatusByType,
  customDocumentTypeDeadlineByCustomDocumentTypeId,
}) {
  if (
    latestDocumentByStatusByType?.[documentType.id]?.last?.status ===
    "disapproved"
  ) {
    return DocumentStatus.REJECTED;
  }

  if (
    documentType?.with_deadline &&
    latestDocumentByStatusByType?.[documentType.id]?.approved === undefined &&
    latestDocumentByStatusByType?.[documentType.id]?.last?.status !==
      "pending" &&
    latestDocumentByStatusByType?.[documentType.id]?.last?.status !==
      "disapproved" &&
    customDocumentTypeDeadlineByCustomDocumentTypeId?.[documentType.id] !==
      undefined
  ) {
    return DocumentStatus.REQUIRES_INPUT;
  }

  if (!latestDocumentByStatusByType?.[documentType.id]?.last) {
    return DocumentStatus.ABSENT;
  }

  if (
    latestDocumentByStatusByType?.[documentType.id]?.last?.status === "pending"
  ) {
    return DocumentStatus.IN_REVIEW;
  }

  return getDocumentStatusByDate(
    latestDocumentByStatusByType?.[documentType.id]?.approved?.expire_at,
  );
}
