import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { Modal } from "components/Modal";
import { routes } from "routes";
import { api } from "api";
import { last } from "lodash";

import { useSelectedFleet } from "hooks/useSelectedFleet";

import { Button } from "components/Button";
import { LoadingComp } from "components/LoadingComp";
import {
  destroySnack,
  errorSnack,
  loadingSnack,
  successSnack,
} from "helpers/message";
import { useLogout } from "hooks/useLogout";

import { VehicleDetailsForm } from "./VehicleDetailsForm";
import { WrapperScreen } from "../WrapperScreen";
import { initializeVehicleDocument } from "./helpers";
import {
  DocumentType,
  getLatestDocumentByStatusByType,
  usePredefinedVehicleDocumentTypes,
  useQueryCustomDocumentTypes,
  ValidationWrapper,
} from "../../documents";
import { ReviewDocumentForm } from "./ReviewDocumentForm";
import styles from "../CreateNewVehiclePage/CreateNewVehiclePage.module.scss";

const initialVehicleDetailsDocument = {
  registration: "",
  area: "",
  year: "",
  make: "",
  model: "",
  colour: "",
  emissions: "",
  nctExpiry: "",
  roadTaxExpiry: "",
};

export function ReviewNewVehiclePage({ isEditPage = false }) {
  const ldClient = useLDClient();
  const navigate = useNavigate();
  const logOut = useLogout();
  const [vehicleDetailsDocument, setVehicleDetailsDocument] = useState(
    initialVehicleDetailsDocument,
  );
  const { releaseAddNewVehicle } = useFlags();
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  const { selectedFleetId: fleetId, selectedDriverProfile } =
    useSelectedFleet();

  const {
    data: vehicleAplications,
    isLoading: isGetting,
    refetch,
    error,
    isUninitialized,
  } = api.endpoints.getVehicleApplications.useQuery({
    driverId: selectedDriverProfile?.id,
  });

  const [deleteDocument, { isLoading: isDeleting, reset: resetDeleting }] =
    api.endpoints.deleteDocument.useMutation();

  const [
    createNewDocumentRequest,
    {
      error: creatingError,
      isLoading: isCreating,
      isSuccess: isCreatingSucceed,
      isError: isCreatingFailed,
      reset: resetCreating,
    },
  ] = api.endpoints.createDocument.useMutation();

  const {
    predefinedVehicleDocumentTypes,
    customVehicleApplicationDocumentTypes,
    isLoading: areDocumentTypesDetailLoading,
  } = useQueryCustomDocumentTypes({
    currentCompanyId: fleetId,
  });

  const vehicleDocuments = [
    ...predefinedVehicleDocumentTypes,
    ...customVehicleApplicationDocumentTypes,
  ].filter((doc) => doc.id !== DocumentType.VEHICLE_DETAILS);

  const { data: fleetPublicData } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );

  const vehicleLookupService =
    fleetPublicData?.data?.vehicle_lookup_service ?? "manual";

  const predefinedVehicleDocumentTypesToSubmit =
    usePredefinedVehicleDocumentTypes();

  const onReturnBack = () => {
    navigate(routes.documents.getURL(), { replace: true });
  };
  useEffect(() => {
    if (isUninitialized || error?.status == 403) {
      logOut();
    }
  }, [isUninitialized, error]);

  useEffect(() => {
    if (!releaseAddNewVehicle) {
      onReturnBack();
    }
  }, [releaseAddNewVehicle]);

  useEffect(() => {
    if (isCreating) {
      loadingSnack({
        content: t("processing.documentSaving"),
        duration: 0,
        key: "saving_document",
      });
    } else {
      destroySnack("saving_document");
    }
  }, [isCreating, t]);

  useEffect(() => {
    if (isCreatingSucceed) {
      successSnack({
        content: t("success.documentSaved"),
      });

      resetCreating();
      refetch();
    }
  }, [isCreatingSucceed, resetCreating, t]);

  useEffect(() => {
    if (isCreatingFailed) {
      const errorMessage = creatingError?.data?.message;

      if (errorMessage === undefined) {
        errorSnack({
          content: t("error.documentNotSaved"),
          duration: 8,
        });
      } else {
        errorSnack({
          content: t("error.documentNotSavedWithReason", {
            reason: errorMessage,
          }),
          duration: 8,
        });
      }
    }
  }, [isCreatingFailed, t, creatingError?.data?.message]);

  useEffect(() => {
    if (isDeleting) {
      loadingSnack({
        content: t("processing.clearing"),
        duration: 0,
        key: "clearing",
      });
      resetDeleting();
      refetch();
    } else {
      destroySnack("clearing");
    }
  }, [isDeleting, t]);

  const currentVehicleApplication = last(
    vehicleAplications?.data.filter(({ id }) => id == vehicleId),
  );

  useEffect(() => {
    setVehicleDetailsDocument(
      initializeVehicleDocument(currentVehicleApplication),
    );
  }, [currentVehicleApplication]);

  useEffect(() => {
    const context = ldClient.getContext();
    const nextContextKey = String(fleetId);

    if (fleetId !== null && context?.key !== nextContextKey) {
      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });
    }
  }, [fleetId, ldClient]);

  if (isGetting || isDeleting || isCreating || areDocumentTypesDetailLoading) {
    return <LoadingComp loading fullScreen />;
  }

  return (
    <WrapperScreen key={vehicleId}>
      <div className={styles.form}>
        <ValidationWrapper>
          <VehicleDetailsForm
            isEditPage={false}
            vehicleLookupService={vehicleLookupService}
            document={vehicleDetailsDocument}
            buttons={[]}
          />
        </ValidationWrapper>
      </div>
      {vehicleDocuments.map((doc) => {
        const lastDocumentOfCurrentType = getLatestDocumentByStatusByType(
          currentVehicleApplication?.documents,
        )[doc.id]?.last;

        const isPredefined = predefinedVehicleDocumentTypesToSubmit
          .map(({ id }) => id)
          .includes(doc.id);

        return (
          <div key={doc.id} className={styles.form}>
            <ReviewDocumentForm
              isEditPage={isEditPage}
              documentTypeDetails={doc}
              currentDocumentData={lastDocumentOfCurrentType}
              onDelete={deleteDocument}
              onCreate={({ extraInfo, expireAt, files }) => {
                createNewDocumentRequest({
                  type: isPredefined ? doc.id : DocumentType.CUSTOM_DOCUMENT,
                  customDocumentTypeId: isPredefined ? undefined : doc.id,
                  extraInfo,
                  expireAt,
                  files,
                  documentableId: vehicleId,
                  documentableType: "vehicle_application",
                });
              }}
            />
          </div>
        );
      })}
      <ValidationWrapper>
        <Button
          variant="primary"
          shape="round"
          className={styles.goBack}
          block={true}
          fontWeight="bold"
          onClick={onReturnBack}
          children={t("action.back")}
        />
        <Modal />
      </ValidationWrapper>
    </WrapperScreen>
  );
}
