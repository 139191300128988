import { Modal } from "components/Modal";
import styles from "./Gallery.module.scss";

export function Gallery(props) {
  return <div className={styles.gallery} {...props} />;
}

export function GalleryItem(props) {
  return <div className={styles.galleryItem} {...props} />;
}

export function GalleryImage({ onClick = () => undefined, ...otherProps }) {
  return (
    <button
      type="button"
      className={styles.galleryImageWrapper}
      onClick={onClick}
    >
      <img className={styles.galleryImage} alt="" {...otherProps} />
    </button>
  );
}

export function GalleryCaption(props) {
  return <div className={styles.galleryCaption} {...props} />;
}

export function GalleryModal(props) {
  return (
    <Modal
      width={1000}
      closable={true}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={null}
    >
      <div className={styles.galleryModalImageWrapper}>
        <img
          className={styles.galleryModalImage}
          src={props.currentImage}
          alt=""
        />
      </div>
    </Modal>
  );
}
