import { getLatestTermsAndConditionsVersion } from "i18n";
import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      checkLatestUserAgreementVersion: build.query({
        providesTags: [TagTypes.ACCEPTANCE],
        query: () => ({
          url: "user-agreement-acceptances",
          params: {
            version: getLatestTermsAndConditionsVersion(),
          },
        }),
      }),

      acceptLatestUserAgreementVersion: build.mutation({
        invalidatesTags: [TagTypes.ACCEPTANCE],
        query: () => ({
          url: "user-agreement-acceptances",
          method: "POST",
          data: {
            version: getLatestTermsAndConditionsVersion(),
          },
        }),
      }),

      checkAcceptanceOfTermsAndConditionsForFleet: build.query({
        providesTags: [TagTypes.FLEET_TERMS_AND_CONDITIONS],
        query: ({ fleetId }) => ({
          url: `terms-and-conditions/${fleetId}`,
        }),
      }),
      acceptTermsAndConditionsForFleet: build.mutation({
        invalidatesTags: [TagTypes.FLEET_TERMS_AND_CONDITIONS],
        query: (fleet_id) => ({
          url: `terms-and-conditions`,
          method: "POST",
          data: {
            fleet_id,
          },
        }),
      }),
    }),
  });
}
