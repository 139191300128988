import { Modal as AntdModal } from "antd";
import styles from "./Modal.module.scss";

export function Modal({
  open,
  title = "",
  footer,
  closable = false,
  zIndex = 2000,
  centered = true,
  children,
  ...rest
}) {
  console.log("footer", footer);
  return (
    <AntdModal
      className={styles.modalWrapper}
      open={open}
      title={title}
      footer={footer}
      closable={closable}
      zIndex={zIndex}
      centered={centered}
      {...rest}
    >
      {children}
    </AntdModal>
  );
}

Modal.config = AntdModal.config;
Modal.confirm = AntdModal.confirm;
Modal.destroyAll = AntdModal.destroyAll;
Modal.error = AntdModal.error;
Modal.info = AntdModal.info;
Modal.success = AntdModal.success;
Modal.useModal = AntdModal.useModal;
Modal.warn = AntdModal.warn;
Modal.warning = AntdModal.warning;
