import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import { ConfirmationModal } from "components/ConfirmationModal";
import { Modal } from "components/Modal";
import { routes } from "routes";
import { Label } from "components/Label";
import { Field } from "components/Field";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import { setNewVehicleRegistratioinNumber } from "store/slices/addNewVehicleSlice";
import { DocumentType } from "../../documents/DocumentType";
import "./CTAButton.scss";
import { Input } from "components/Input";

export const CTAButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [vehicleReplacementFormVisible, setVehicleReplacementFormVisible] =
    useState(false);

  const goToAddNewVehiclePage = useCallback(() => {
    navigate(
      routes.addNewVehicle.getURL({
        documentTypeId: DocumentType.VEHICLE_DETAILS,
      }),
    );
  }, []);
  const onCloseModal = useCallback(
    () => setVehicleReplacementFormVisible(false),
    [],
  );
  const onOpenNextModal = useCallback(
    () => setVehicleReplacementFormVisible(true),
    [],
  );

  const shownConfirmationModal = () => {
    Modal.confirm({
      title: t("pageAddNewVehicle.deactivateReplacingVehicle"),
      icon: null,
      content: "",
      okText: t("optionGeneral.yes"),
      cancelText: t("optionGeneral.no"),
      className: "custom-vehicle-replacement-promp",
      onOk: onOpenNextModal,
      onCancel: goToAddNewVehiclePage,
    });
  };

  const onSubmit = ({ registrationNumber }) => {
    dispatch(setNewVehicleRegistratioinNumber(registrationNumber));
    goToAddNewVehiclePage();
  };

  return (
    <>
      <Button
        shape="round"
        variant="primary"
        size="sm"
        onClick={shownConfirmationModal}
        className="custom-add-new-vehicle"
      >
        {t("pageAddNewVehicle.addVehicle")}
      </Button>
      <ConfirmationModal
        className="custom-replace-vehicle-registration-modal"
        closable
        okText={t("action.submit")}
        visible={vehicleReplacementFormVisible}
        onCancel={onCloseModal}
        onConfirm={form.submit}
      >
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Field htmlFor="registrationNumber" dense>
            <Label className="custom-replace-registration-number-label">
              {t("pageAddNewVehicle.replaceRegistrationNumberLabel")}
            </Label>
            <Form.Item
              name="registrationNumber"
              rules={[
                {
                  required: true,
                  message: t("error.registrationNumberRequired"),
                },
              ]}
            >
              <Input
                id="registrationNumber"
                name="registrationNumber"
                className="custom-replace-registration-number-input"
              />
            </Form.Item>
          </Field>
        </Form>
      </ConfirmationModal>
    </>
  );
};
