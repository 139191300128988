export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      inviteUserApi: build.mutation({
        query: ({ driver_profile_id }) => ({
          data: {
            driver_profile_id,
          },
          url: "invite/driver",
          method: "POST",
        }),
      }),

      inviteFleetAdmin: build.mutation({
        query: (data) => ({
          data,
          url: "invite/fleet-admin",
          method: "POST",
        }),
      }),

      acceptUser: build.mutation({
        query: ({params, body}) => ({
            data: body,
            params: {
              driver_profile_id: params.driver_profile_id,
              signature: params.signature,
            },
            url: "invite/accept-driver",
            method: "POST",
          }),
      }),

      inviteDriverBulk: build.mutation({
        query: ({ ids }) => ({
          method: "POST",
          params: {
            ids,
          },
          url: "invite/driver-bulk",
        }),
      }),
    }),
  });
}
