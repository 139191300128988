import { useTranslation } from "react-i18next";

import { DocumentForm as DocumentFormInner } from "pages/documents/components/DocumentForm";

export function ReviewDocumentForm({
  isEditPage = false,
  documentTypeDetails,
  currentDocumentData,
  onDelete,
  onCreate,
}) {
  const { t } = useTranslation();
  const isCurrentDocumentExist = currentDocumentData !== undefined;

  const buttonsIfDocumentNotExist = [
    {
      block: true,
      variant: "primary",
      shape: "round",
      fontWeight: "bold",
      onClick: "onSave",
      children: t("action.save"),
    },
  ];

  return (
    <DocumentFormInner
      isEditPage={!isCurrentDocumentExist && isEditPage}
      document={currentDocumentData}
      documentType={documentTypeDetails}
      buttons={
        !isEditPage
          ? []
          : !isCurrentDocumentExist
            ? buttonsIfDocumentNotExist
            : [
                {
                  block: true,
                  variant: "default",
                  importance: "error",
                  shape: "round",
                  fontWeight: "bold",
                  onClick: () => {
                    onDelete({ id: currentDocumentData.id });
                  },
                  children: t("action.clear"),
                },
              ]
      }
      onSave={onCreate}
    />
  );
}
