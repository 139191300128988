import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { api } from "api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate } from "react-router-dom";
import { routes } from "routes";

import { Container } from "components/Container";
import { WaveLayout } from "components/WaveLayout";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { LoadingComp } from "components/LoadingComp";

import styles from "./TermsAndConditionsForFleetPage.module.scss";

export function TermsAndConditionsForFleetPage() {
  const {
    selectedFleetId,
    isError,
    error,
    isLoading: isFleetDataLoading,
  } = useSelectedFleet();
  console.log(isError, error, isFleetDataLoading);

  const { releaseFleetSpecificPolicies } = useFlags();
  const { data: fleetPublicData, isLoading } =
    api.endpoints.getFleetPublicInfoById.useQuery({
      fleetId: selectedFleetId,
    });

  if (releaseFleetSpecificPolicies === false) {
    return <Navigate to={routes.walletHome.getURL()} replace />;
  }

  return (
    <WaveLayout>
      <Container>
        {isLoading ? (
          <LoadingComp loading fullScreen />
        ) : (
          <ReactMarkdown
            className={styles.markdownWrapper}
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({ children, className, node, ...rest }) => (
                <p className={styles.p} {...rest}>
                  {children}
                </p>
              ),
            }}
            children={fleetPublicData?.data?.terms_and_conditions}
          ></ReactMarkdown>
        )}
      </Container>
    </WaveLayout>
  );
}
