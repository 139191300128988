import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { routes } from "routes";

import { Checkbox } from "components/Checkbox";
import { Modal } from "components/Modal";
import { Field } from "components/Field";
import { useSelectedFleet } from "hooks/useSelectedFleet";

import styles from "./FleetTermsAndConditionsModal.module.scss";
import { Button } from "components/Button";

export const FleetTermsAndConditionsModal = ({ open, onSubmit }) => {
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState(false);

  const { selectedFleet, selectedFleetId } = useSelectedFleet();
  const { url_slug: fleetSlug, name: fleetName = "fleet's" } =
    selectedFleet ?? {};

  return (
    <Modal
      open={open}
      title={t("modalTermsNotAccepted.termsNotAccepted")}
      footer={null}
      closable={false}
      zIndex={2000}
      centered
    >
      <Field className={styles.link}>
        <Checkbox
          id="terms"
          name="terms"
          checked={isAccepted}
          onChange={({ target }) => setIsAccepted(target?.checked)}
        >
          <Trans
            i18nKey="modalTermsNotAccepted.fleetTermsAndConditions"
            components={{
              fleetName,
              termslink: (
                <Link
                  to={routes.termsAndConditionsContentFromFleet.getURL(
                    fleetSlug,
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </Checkbox>
      </Field>
      <div className={styles.disclaimer}>
        <Trans
          i18nKey="modalTermsNotAccepted.fleetTermsAndConditionsDisclaimer"
          components={{
            fleetName,
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="primary"
          shape="round"
          disabled={!isAccepted}
          onClick={() => onSubmit(selectedFleetId)}
        >
          {t("action.submit")}
        </Button>
      </div>
    </Modal>
  );
};
