import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";

import { api, selectDriverProfilesFromGetMeResult } from "api";
import {
  selectSelectedFleetId,
  setSelectedFleetId,
} from "store/slices/selectedFleetIdSlice";
import { selectDriverApplicationsFromGetMeResult } from "api/endpoints/users";
import { useLogout } from "hooks/useLogout";

export function getDerivedValuesFromDriverProfiles(driverProfiles) {
  const driverProfilesByFleetId = keyBy(
    driverProfiles,
    (driverProfile) => driverProfile?.fleet?.id,
  );

  const fleets = driverProfiles.map((driverProfile) => driverProfile?.fleet);

  const fleetsById = keyBy(fleets, "id");

  return {
    driverProfilesByFleetId,
    fleets,
    fleetsById,
  };
}

export function useSelectedFleet() {
  const dispatch = useDispatch();
  const logOut = useLogout();
  const selectFleetId = useCallback(
    (fleetId) => {
      dispatch(setSelectedFleetId(fleetId));
    },
    [dispatch],
  );

  const {
    driverProfiles,
    driverApplications = [],
    isLoading,
    isError,
    error, // Read: https://redux-toolkit.js.org/rtk-query/usage/error-handling
    isUninitialized,
  } = api.endpoints.getMe.useQuery(undefined, {
    selectFromResult: (result) => {
      const { data, ...rest } = result;
      return {
        driverProfiles: selectDriverProfilesFromGetMeResult(result),
        driverApplications: selectDriverApplicationsFromGetMeResult(result),
        ...rest,
      };
    },
  });

  const { driverProfilesByFleetId, fleets, fleetsById } = useMemo(
    () => getDerivedValuesFromDriverProfiles(driverProfiles),
    [driverProfiles],
  );

  const selectedFleetId = useSelector(selectSelectedFleetId);
  const selectedDriverProfile = driverProfilesByFleetId[selectedFleetId];
  const selectedFleet = fleetsById[selectedFleetId];

  const driverApplicationByFleetId =
    driverApplications.filter(
      ({ fleet_id }) => fleet_id === selectedFleetId,
    )[0] || {};

  const selectedFleetCurrency = useMemo(() => {
    const fleetCurrency = selectedFleet?.currency;
    if (!fleetCurrency) {
      return "--";
    }

    return new Intl.NumberFormat(window.navigator.language, {
      style: "currency",
      currency: fleetCurrency,
      currencyDisplay: "narrowSymbol",
    })
      .format(0)
      .replace(/[0-9]./g, "");
  }, [selectedFleet]);

  if (!isUninitialized && !isLoading && isEmpty(driverProfiles)) {
    logOut();

    return {};
  }

  return {
    selectFleetId,
    selectedFleetId,

    selectedFleet,
    selectedDriverProfile,

    driverProfiles,
    driverProfilesByFleetId,
    driverApplications,
    driverApplicationByFleetId,
    fleets,
    fleetsById,

    selectedFleetCurrency,
    isLoading,
    isError,
    error,
  };
}
